<h3 class="ui-portal-title">
  Recommendations
</h3>
@if (!auth.getAdministratedCompany()) {
  <!-- No company -->
  <div class="card">
    <img src="/assets/img/warning-orange.svg">
    Recommendations Currently Unavailable<br>
    <br>
    We do not have any recommendations for you yet. To provide you with the most relevant matches, we need to learn more about your business. Please provide additional details or update your company information to help us serve you better here.
  </div>
} @else {
  @if (model.length) {
    <!-- Recommendations exist -->
    <div class="card">
      <img src="/assets/img/check-green.svg">
      Here Are Your Recommended Items!<br>
      <br>
      Based on the information you provided, we've curated a selection of items that we believe best meet your needs. Check them out and see how they can benefit your business!
    </div>
  } @else {
    <!-- Recommendations not exist -->
    <div class="card">
      <img src="/assets/img/warning-orange.svg">
      Recommendations Currently Unavailable<br>
      <br>
      At the moment, we do not have recommendations that perfectly align with your specific needs. We are continuously updating our offerings. Please check back later for potential solutions that may benefit your business, or consider modifying your request to explore other options.
    </div>
  }
}

@if (!auth.getAdministratedCompany() || !model.length) {
  <a class="btn-primary" routerLink="../company">
    Edit My Company Profile
  </a>
}

<div class="mp-app-tiles">
  @for (item of model; track item.ID) {
      <app-tile [item]="item" [id]="item.ID" [rating]="item.Rating" [img]="item.ImageURL" [label]="item.Name" [desc]="item.Description" [item]="item" [isDraft]="!item.Published" [stars]="item.Stars" [official]="item.Official === 1"></app-tile>
  }
</div>

