import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {ActivatedRoute, ResolveFn, RouterLink} from '@angular/router';
import {TileComponent} from '../../controls/tile/tile';
import {APIService} from '../../services/api.service';
import {AuthService} from '../../services/authService';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {IPackageData} from '../../services/packageTypes';

export const hihRecommendationsResolver: ResolveFn<any> = () => {
  const api = inject(APIService);
  const auth = inject(AuthService);
  if (!auth.getAdministratedCompany()) {
    return;
  }
  return api.getHihRecommendations();
}

@Component({
  standalone: true,
  selector: 'oex-hih-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrl: './recommendations.component.scss',
  imports: [
    TileComponent,
    RouterLink
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecommendationsComponent implements OnInit {
  data$ = this.route.data.pipe(takeUntilDestroyed());
  model: IPackageData[] = [];

  constructor(public auth: AuthService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.data$.subscribe(d => {
      if (!d.data) {
        return;
      }
      this.model = d.data?.items;
    });
  }
}
